import React from "react"
import Layout from "@/components/layout"
import SolicitacaoSegundaChamadaForm from "@/components/SolicitacaoSegundaChamadaForm"

const SolicitacaoSegundaChamada = () => {
  return (
    <Layout>
      <SolicitacaoSegundaChamadaForm />
    </Layout>
  );
};

export default SolicitacaoSegundaChamada;
